<template>
  <app-card-code
    class="pb-5"
    title=""
    code=""
  >
    <h3 class="ml-5">
      <v-icon
        class="mr-1"
        color="primary"
        @click="volver_poliza"
      >
        {{ icons.mdiArrowLeftCircle }}
      </v-icon>
      Información del cliente
    </h3>
    <v-row justify="space-around">
      <!-- <v-card
        width="800"
      > -->
      <!-- <v-card-title class="white--text mt-0 fondo-card">
          <v-avatar size="80">
            <v-icon class="ml-2 white--text">
              {{ icons.mdiAccountStar }}
            </v-icon>
          </v-avatar>
          <p
            class="ml-8 white--text"
          >
            Perfil de Usuario
          </p>
        </v-card-title> -->

      <v-card-text>
        <v-row
          class="mb-15 mt-5 ml-3 mr-3 "
        >
          <div class="text-overline mb-4">
            Información básica del asegurado
          </div>
          <v-col
            md="12"
            cols="11"
          >
            <span class="font-weight-medium me-2">Rut Cliente:</span>
            <span
              v-mask="maskRut"
              class="text--secondary"
            >{{ maskRut(rut)[0] }}</span>
          </v-col>
          <v-col
            md="12"
            cols="11"
          >
            <span class="font-weight-medium me-2">Nombres y apellidos del asegurado:</span>
            <span class="text--secondary">{{ nombres }} {{ primerApellido }}</span>
          </v-col>
          <v-col
            md="12"
            cols="11"
          >
            <span class="font-weight-medium me-2">Fecha de nacimiento:</span>
            <span class="text--secondary text-capitalize">{{ fechaNacimiento }}</span>
          </v-col>
          <v-col
            md="6"
            cols="11"
          >
            <span class="font-weight-medium me-2">Correo:</span>
            <span class="text--secondary">{{ correo }}</span>
          </v-col>
          <v-col
            md="6"
            cols="11"
          >
            <span class="font-weight-medium me-2">Teléfono:</span>
            <span class="text--secondary">{{ telefono }}</span>
          </v-col>
          <div class="text-overline mt-4">
            Detalles de la dirección
          </div>
          <v-col
            md="12"
            cols="11"
          >
            <span class="font-weight-medium me-2">Dirección:</span>
            <span class="text--secondary">{{ calle }} {{ Numero }}, {{ departamento }}, {{ comuna }}, {{ localidad }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- </v-card> -->
    </v-row>
  </app-card-code>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiCheck, mdiCached, mdiMagnify, mdiAccount, mdiArrowLeftCircle } from '@mdi/js'

import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import { polizastService } from '../../../../service/poliza.service'
import { maskRut, cleanMaskRut } from '@/helpers/rut'

export default {
  components: {
    AppCardCode,
  },
  filters: {
    formatFecha(valor) {
      const dato = valor.split('-')

      return typeof dato === typeof [] ? `${dato[2]}-${dato[1]}-${dato[0]}` : 'Sin información'
    },
  },
  mounted() {
    // this.bucarRut(this.$router.currentRoute.params.rut)
    // this.informacionCliente = JSON.parse(localStorage.getItem('informacionCliente'))
    this.bucarRut(JSON.parse(localStorage.getItem('informacionCliente')), this.$router.currentRoute.params.rut)
  },
  setup() {
    const rut = ref('')
    const nombres = ref('')
    const primerApellido = ref('')
    const segundoApellido = ref('')
    const fechaNacimiento = ref('')
    const correo = ref('')
    const telefono = ref('')
    const calle = ref('')
    const numero = ref('')
    const departamento = ref('')
    const comuna = ref('')
    const localidad = ref('')
    const activo = ref(null)
    const textActivo = ref('')

    return {
      maskRut,
      rut,
      nombres,
      primerApellido,
      segundoApellido,
      fechaNacimiento,
      correo,
      telefono,
      calle,
      numero,
      departamento,
      comuna,
      localidad,
      activo,
      textActivo,

      icons: {
        mdiClose,
        mdiCheck,
        mdiCached,
        mdiMagnify,
        mdiAccount,
        mdiArrowLeftCircle,
      },
    }
  },
  methods: {
    formatFecha(valor) {
      const dato = valor.split('-')

      return typeof dato === typeof [] ? `${dato[2]}-${dato[1]}-${dato[0]}` : 'Sin información'
    },
    volver_poliza() {
      this.$router.push({
        name: 'poliza_id',
        params: {
          rut: this.rut,
        },
      })
    },
    async bucarRut(itemRut, propuesta) {
      this.respuestaRut = false
      this.stipeActive = true

      // const rutFormat = cleanMaskRut(itemRut)

      // await polizastService.getPoliza(rutFormat).then(resp => {
      //   if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
      const informacion = itemRut
      this.rut = propuesta
      this.nombres = informacion.nombres
      this.primerApellido = informacion.primerApellido
      this.segundoApellido = informacion.segundoApellido
      this.fechaNacimiento = this.formatFecha(informacion.fechaNacimiento)
      this.correo = informacion.correo
      this.telefono = `(${informacion.telefono.substr(0, 3)}) - ${informacion.telefono.substr(
        3,
        3,
      )}-${informacion.telefono.substr(6, 4)}`
      this.calle = informacion.direccion.calle
      this.numero = informacion.direccion.numero
      this.departamento = informacion.direccion.departamento
      this.comuna = informacion.direccion.comuna
      this.localidad = informacion.direccion.localidad
      this.activo = informacion.activo
      if (this.activo === true) {
        this.textActivo = 'Activo'
      } else {
        this.textActivo = 'Inactivo'
      }

      //   }
      // })
    },
  },
}
</script>
